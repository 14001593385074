import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const InfoBlock = makeShortcode("InfoBlock");
const Link = makeShortcode("Link");
const Flash = makeShortcode("Flash");
const Card = makeShortcode("Card");
const Box = makeShortcode("Box");
const List = makeShortcode("List");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "🤯-significado",
      "style": {
        "position": "relative"
      }
    }}>{`🤯 Significado`}<a parentName="h1" {...{
        "href": "#%F0%9F%A4%AF-significado",
        "aria-label": "🤯 significado permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <InfoBlock mdxType="InfoBlock">
      <p><em parentName="p">{`"Porque mientras pueda utilizarse la fuerza,`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`¿para qué el diálogo?`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`Sin embargo, las palabras siempre conservarán su poder,`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`las palabras hacen posible que algo tome significado`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`y si se escuchan,`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`enuncian la verdad.`}</em><br parentName="p"></br>{`
`}<strong parentName="p">{`V de Vendetta`}</strong>{`  `}</p>
    </InfoBlock>
    <hr></hr>
    <p>{`Hay una película famosa en Internet llamada `}<Link to="https://www.youtube.com/watch?v=ByY6j0qzOyM" mdxType="Link">{`Good Copy Bad Copy`}</Link>{`
realizada en 2007 sobre la propiedad intelectual, la piratería y la lucha contra la ley de derechos de autor. La tesis central
es que el documento que más protege nuestra libertad ya no es la Declaración
de Independencia, ni la Carta de Derechos: es la matriz 50x50 que es la clave de descifrado de los discos blu-ray.`}</p>
    <p>{`Esto está desactualizado, pero la premisa es fascinante. La lucha por la libertad no se lleva a cabo
con lenguaje natural en forma de retórica política: está `}<strong parentName="p">{`desmenuzado en protocolos técnicos`}</strong>{`.
Lo que más importa no es lo que dice, sino qué funciones puede hacer que su lenguaje ejecute.`}</p>
    <p>{`Esto ha sido así desde que Phil Zimmerman publicó  el código fuente `}<Link to="https://books.google.co.za/books/about/PGP_Source_Code_and_Internals.html?id=xR4ZAQAAIAAJ&redir_esc=y" mdxType="Link">{`PGP como un libro`}</Link>{`
en 1995, usando la primera enmienda para eludir algo llamado Ley de Control de Exportación de Armas.
La criptografía es tan poderosa que muchos gobiernos la consideran un arma. Esto se
debe a que ejecuta el discurso libremente, sin necesidad de protección legal o política y,
por lo tanto, fuera del ámbito del control legal o político.`}</p>
    <Flash mdxType="Flash">
      <Card mdxType="Card">
        <p>{`P: La retórica política no es tan poderosa como la `}{`_`}{`_`}{`_`}{`_`}{`_`}{`_`}{`_`}{`_`}{`_`}{` que puede hacer que su lenguage `}{`_`}{`_`}{`_`}{`_`}{`_`}{`_`}{`_`}</p>
        <p>{`R: funcion(es), ejecute.`}</p>
      </Card>
    </Flash>
    <h2 {...{
      "id": "los-medios",
      "style": {
        "position": "relative"
      }
    }}>{`Los Medios`}<a parentName="h2" {...{
        "href": "#los-medios",
        "aria-label": "los medios permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`Ahora utilizamos ampliamente criptografía para asegurar nuestra capacidad de hablar libremente sobre
lo que consideramos valioso, sin la necesidad de confiar en instituciones corruptibles. Es un cambio profundo
en la evolución de nuestra capacidad de crear significado, en el sentido más general. `}</p>
    <p>{`Los medios por los cuales creamos tal significado no son propiedad de nadie, y el medio, que es
tanto una red como un lenguaje de programación, se puede leer de una manera esencialmente perfecta. Es decir, las
funciones que ejecuta nuestro lenguaje producen resultados deterministas que se acuerdan globalmente.
Sin embargo, se vuelve aún más extraño. Como dice Vitalik en `}<a parentName="p" {...{
        "href": "/learn/module-1/understanding-ethereum"
      }}>{`entendiendo Ethereum`}</a>{`;
los contratos (código) y las cuentas de propiedad externa (personas) son los dos, ciudadanos de primera clase en Ethereium.
Simon de la Rouviere lo describe así:`}</p>
    <blockquote>
      <p parentName="blockquote">{`“Es dudoso que los programas desarrollen el deseo de conectarse por el simple hecho de hacerlo (como lo hacemos nosotros),
a menos que los programemos para que lo hagan. Sim embargo, los beneficios de saber que un cómputo se realizó de manera
verificable es como inventar una religión de programas `}{`[...]`}{` con protocolos de cómputos
verificables, un programa conocerá la mente de otros programas. Excepto que, a diferencia de la biología, donde es
imperfecta, sabrá exactamente el estado y la capacidad de procesamiento. Ya no existe esta idea
de servidores de datos y lógica conectados de manera dispar a través de la red.”`}</p>
    </blockquote>
    <h2 {...{
      "id": "el-meollo-del-asunto",
      "style": {
        "position": "relative"
      }
    }}>{`El meollo del asunto`}<a parentName="h2" {...{
        "href": "#el-meollo-del-asunto",
        "aria-label": "el meollo del asunto permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`Entonces, ¿qué `}<em parentName="p">{`significa`}</em>{` realmente Ethereum? Afortunadamente, las respuestas más precisas se encuentran`}<br parentName="p"></br>{`
`}{`en exploradores de bloques, no en ensayos cortos. Sin embargo, aquī podemos hacer un intento natural al
considerar nuestra imaginación comunitaria: el logotico de ochos lados. `}</p>
    <p>{`El `}<Link to="https://es.wikipedia.org/wiki/Octaedro" mdxType="Link">{`octaedro`}</Link>{` es el tercero
de los `}<Link to="https://es.wikipedia.org/wiki/Sólidos_platónicos" mdxType="Link">{`cinco sólidos`}</Link>{` postulados
por Platón para formar todos los elementos: tierra, agua, fuego, aire y `}<em parentName="p">{`éter`}</em>{`. Mientras que examina el aire en el
`}<em parentName="p">{`Timeo`}</em>{`, el elemento con el que se asocia al octaedro, Platón escribe "existe el tipo más
traslúcido que recibe el nombre de éter (αἰθήρ)". La palabra αἰθήρ en griego homérico
significa "aire puro y fresco" o "cielo despejado". También se traduce a menudo como "quintaesencia": el material
que llena la región del universo sobre la esfera terrestre. El `}<em parentName="p">{`éter`}</em>{` es el aire de
otro reino, que sin embargo interactúa con el nuestro en virtud del significado que representamos
en nuestro uso de los cuatro elementos terrestres.  `}</p>
    <p>{`El octaedro es el sólido medio y, por lo tanto, asociado en la metafísica más moderna con
el corazón, amor, compasión, perdón, y curación. Tiene 8 caras, similar en cierto sentido
al `}<Link to="https://es.wikipedia.org/wiki/Noble_camino_óctuple" mdxType="Link">{`Noble camino óctuple`}</Link>{`.
Como es el caso de todos los sólidos platónicos, es simétrico y por lo tanto su reflejo sigue siendo el mismo.  `}</p>
    <p>{`Tales formas nos recuerda que todos somos solo espejos los unos de los otros. `}</p>
    <p>{`De forma más práctica las , `}<Link to="https://es.wikipedia.org/wiki/Malla_espacial" mdxType="Link">{`mallas espaciales`}</Link>{` son un diseño arquitectóico de uso común, extendido a `}<Link to="https://tinyurl.com/mister-fuller" mdxType="Link">{`cerchas octogonales por Buckminster Fuller`}</Link>{` en su trabajo sobre cúpulas geodésicas. Fuller a menudo se cita en los cículos criptográficos, siendo la opción preferida
para muchos de sus descendientes intelectuales: `}</p>
    <blockquote>
      <p parentName="blockquote">{`“Nunca cambias las cosas luchando contra la realidad existente. Para cambiar algo, construya un nuevo
modelo que haga obsoleto el modelo existente.”`}</p>
    </blockquote>
    <hr></hr>
    <h2 {...{
      "id": "referencias-adicionales",
      "style": {
        "position": "relative"
      }
    }}>{`Referencias adicionales`}<a parentName="h2" {...{
        "href": "#referencias-adicionales",
        "aria-label": "referencias adicionales permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <InfoBlock mdxType="InfoBlock">
      <Box mdxType="Box">
        <p>{`"Conocer es poseer, y cualquier hecho es poseído por todo aquel que conoce, mientras que aquellos que `}<em parentName="p">{`sienten`}</em>{` la verdad son poseídos, no poseedores".`}</p>
        <p><img parentName="p" {...{
            "src": "/images/ethereum.png",
            "alt": "Ethereum Logo"
          }}></img></p>
        <Link to="https://www.youtube.com/watch?v=kffo3pxNO7c" mdxType="Link">ee cummings</Link>
      </Box>
    </InfoBlock>
    <List mdxType="List">
      <Link to="https://www.youtube.com/watch?v=54l8_ewcOlY&list=PLND1JCRq8Vuh3f0P5qjrSdb5eC1ZfZwWJ" mdxType="Link">
        <p>{`Exaptación y Psicotecnologías `}</p>
      </Link>
    </List>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      